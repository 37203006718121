import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { makeStyles } from "@material-ui/styles"
import { Typography } from "@material-ui/core"

const useStyles = makeStyles({
  title: {
    fontWeight: "300",
  },
  date: {
    fontSize: "1rem",
    color: "#999",
    fontWeight: "300",
  },
  body: {
    marginTop: "50px",
  },
})

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark
  const classes = useStyles()
  return (
    <Layout>
      <SEO title={frontmatter.title} />

      <Typography variant="h3" className={classes.title}>
        {frontmatter.title}
      </Typography>
      <Typography variant="h6" className={classes.date}>
        {frontmatter.date}
      </Typography>
      <Typography variant="body1" className={classes.body}>
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </Typography>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
      }
    }
  }
`
