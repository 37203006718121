/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { makeStyles, ThemeProvider } from "@material-ui/styles"
import { Container } from "@material-ui/core"
import { createMuiTheme, createStyles } from "@material-ui/core/styles"
import Header from "./header"
import Footer from "./footer"
import "./layout.css"

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },
})

const theme = createMuiTheme({
  overrides: {
    MuiLink: createStyles({
      root: {
        "&&": {
          textDecoration: "none",
          color: "#912800",
        },
        "&&:hover": {
          color: "#555",
          cursor: "pointer",
        },
      },
    }),
  },
})

const Layout = ({ children }) => {
  const classes = useStyles()

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <Header />
        <Container>
          <main>{children}</main>
        </Container>
        <Footer />
      </div>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
