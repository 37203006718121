import React from "react"
import { Container, Typography, Link } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"

const useStyles = makeStyles({
  footer: {
    marginTop: "auto",
    backgroundColor: "white",
    textAlign: "center",
    paddingTop: "100px",
  },
})

export default function Footer() {
  const classes = useStyles()
  return (
    <footer className={classes.footer}>
      <Container maxWidth="sm">
        <Typography>
          © {new Date().getFullYear()}, Built with
          {` `}
          <Link to="https://www.gatsbyjs.org">Gatsby</Link>
        </Typography>
      </Container>
    </footer>
  )
}
