import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { AppBar, Toolbar, IconButton, Typography } from "@material-ui/core"
import { Home } from "@material-ui/icons"

const useStyles = makeStyles({
  homebutton: {
    color: "grey",
  },
  appbar: {
    backgroundColor: "white",
  },
})

export default function Header() {
  const classes = useStyles()
  return (
    <div>
      <AppBar position="static" className={classes.appbar} elevation={0}>
        <Toolbar>
          <Typography style={{ flexGrow: 1 }}></Typography>
          <Link to="/">
            <IconButton className={classes.homebutton} aria-label="Home">
              <Home />
            </IconButton>
          </Link>
        </Toolbar>
      </AppBar>
    </div>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}
